import React from 'react'
import logo from '../assets/images/logo-with-water.png'

const Banner = props => (
  <section id="banner" className="major">
    <div className="inner">
      <header className="major">
        {/* <h1 style={{ width: '70%', display: 'inline-block' }}>
          Colossians 3:12 Ministries
        </h1> */}
        <img
          src={logo}
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '30%',
            display: 'block',
          }}
        />
      </header>
      <div className="content">
        <p>
          Putting on a heart of compassion...
          <br />
          Let's take a step outside our comfort zone and reach out with
          gentleness and meekness;
          <br />
          serving others where they live.
        </p>
        <ul className="actions">
          <li>
            <a href="#one" className="button next scrolly">
              Get Started
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
)

export default Banner
