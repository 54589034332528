import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import dots from '../assets/images/dots.jpg'
import diamonds from '../assets/images/diamonds.jpg'
// import pic03 from '../assets/images/pic03.jpg'
// import pic04 from '../assets/images/pic04.jpg'
// import pic05 from '../assets/images/pic05.jpg'
// import pic06 from '../assets/images/pic06.jpg'

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Colossians 3:12 Ministries"
          meta={[
            {
              name: 'description',
              content:
                'Colossians 3:12 Ministries, a ministry about compassionate care',
            },
            {
              name: 'keywords',
              content:
                'Colossians 3:12 Ministries, Colossians 3:12, Col 3:12, Col3:12',
            },
          ]}
        />

        <Banner />

        <div id="main">
          <section id="two">
            <div className="inner">
              <header className="minor">
                <blockquote>
                  You are the light of the world. A town built on a hill cannot
                  be hidden. Neither do people light a lamp and put it under a
                  bowl. Instead they put it on its stand, and it gives light to
                  everyone in the house. In the same way, let your light shine
                  before others, that they may see your good deeds and glorify
                  your Father in heaven
                </blockquote>
                <em>- Matthew 5:14-16</em>
              </header>
              <br />
              <p>
                I am approaching my 60th birthday in the next couple weeks. As I
                reflect on the past couple years I think about why I want to
                start a new ministry. I mean there are many people out there
                helping people. It’s true. For some reason God has burdened my
                heart for those in need. I was an adopted woman whose adoptive
                father died when I was young, growing up with a widowed mom who
                had no income, I saw first hand how much opportunity there is
                out there to help others.
              </p>
              <p>
                As a young person I longed for someone to come help us. There
                were times when someone did and other times when life was just
                relentlessly difficult. My mom did not ask for help. She wanted
                to try on her own and she did a good job with really no
                resources to work with. As time moved on there were more issues
                with our old farmhouse and property. God protected us in those
                years. But I know without a shadow of a doubt that is a main
                reason both my sister and I are burdened for others.
              </p>
              <p>
                I see single parents struggling with their children, parents and
                spouses of addicts feeling hopelessness and single older people
                whether widowed or just single feeling alone. It squeezes my
                heart to hear their stories. So what can I do? I just kept
                thinking of the Colossians 3:12 passage and what it means for
                me.{' '}
              </p>
              <p>
                It says, “So, as those who have been chosen by God, holy and
                beloved,{' '}
                <em>
                  put on a heart of compassion, kindness, humility, gentleness
                  and patience
                </em>
                ; bearing with one another; just as the Lord forgave you, so
                also should you.”
              </p>
              <p>
                The goal of Colossians 3:12 is to give people resources so they
                know how to help and where to look when they see someone in
                need. Right now after just launching this we have been gathering
                information but we would like to be able to give help where
                needed. I have started to have many interested and asking
                questions. This is great! Our team is expanding and within the
                next 6 months I “hope” we should be able to offer assistance
                when someone inquires.{' '}
              </p>
              <p>We are just getting this underway so bear with us!</p>
              <p>
                <em>- Nicki Corinne White</em>
              </p>
              {/* <ul className="actions">
                <li>
                  <Link to="/resources" className="button next">
                    Get Started
                  </Link>
                </li>
              </ul> */}
            </div>
          </section>
          <section id="one" className="tiles">
            <article style={{ backgroundImage: `url(${dots})` }}>
              <header className="major">
                <h3>Resources </h3>
                <p>
                  A list of resources to aid in your search for assistance
                  options
                </p>
              </header>
              <Link to="/resources" className="link primary" />
            </article>
            <article style={{ backgroundImage: `url(${diamonds})` }}>
              <header className="major">
                <h3>Compassion in Action</h3>
                <p>
                  Creating a habit of service to those in your life, even when
                  it’s uncomfortable
                </p>
              </header>
              <Link to="/compassion-in-action" className="link primary" />
            </article>
            {/* <article style={{ backgroundImage: `url(${pic03})` }}>
              <header className="major">
                <h3>Magna</h3>
                <p>Lorem etiam nullam</p>
              </header>
              <Link to="/resources" className="link primary" />
            </article>
            <article style={{ backgroundImage: `url(${pic04})` }}>
              <header className="major">
                <h3>Ipsum</h3>
                <p>Nisl sed aliquam</p>
              </header>
              <Link to="/resources" className="link primary" />
            </article>
            <article style={{ backgroundImage: `url(${pic05})` }}>
              <header className="major">
                <h3>Consequat</h3>
                <p>Ipsum dolor sit amet</p>
              </header>
              <Link to="/resources" className="link primary" />
            </article>
            <article style={{ backgroundImage: `url(${pic06})` }}>
              <header className="major">
                <h3>Etiam</h3>
                <p>Feugiat amet tempus</p>
              </header>
              <Link to="/resources" className="link primary" />
            </article> */}
          </section>
        </div>
      </Layout>
    )
  }
}

export default HomeIndex
